import Layout from "../components/Layout";
import React from "react";
import Banner from "../components/Banner";

export default function About() {
    return (
        <Layout title={"About"}>
            <Banner heightSmall={true} backgroundImage={"/media/mv-03.jpg"}>
                <div className={"banner-title"}>
                    <h1>Who We Are</h1>
                </div>
            </Banner>
            <div style={{background: "#e9eff4"}} className="section grid-2">
                <p style={{fontSize: "x-large"}}>
                    For over 35 years, we have offered a wide variety of Made in Japan quality products and production
                    equipment
                    related to Fine Ceramics to overseas customers. We also import natural graphite processed
                    products, graphene, etc.
                </p>
                <div style={{backgroundImage: "url('/media/WhoWeAre photo 2.jpg')", height: "320px"}}
                     className="full-bg"></div>
            </div>
            <div style={{background: "rgb(0, 78, 162)"}} className="section grid-2">
                <div style={{backgroundImage: "url('/media/WhoWeAre photo 3.jpg')", height: "427px"}}
                     className="full-bg"></div>
                <div style={{color: "white"}}>
                    <h1>CEO's Note</h1>
                    <p style={{fontSize: "large"}}>
                        We have expanded since the establishment of the company in 1986 by President and CEO, Yasuhiro
                        Yamanaka.
                        Now, a
                        variety of products related to engineering products and machinery equipment are offered in
                        Taiwan and now,
                        in
                        the U.S.A. Depending on the market of each country, we cater to the specific needs of our
                        clients by
                        bridging
                        the gap between Japan and American-made quality products through import and export.
                    </p>
                </div>
            </div>
            <div style={{background: "#e9eff4", display: "grid", gap: "20px"}} className="section">
                <h1>History</h1>
                <div className="timeline" style={{gridColumn: "span 2"}}>
                    <div className="timeline-item">
                        <span>1986</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>Yasuhiro Yamanaka established Pacific Commerce and Industry Trading Co., Ltd. in Shinjuku,
                            Tokyo, to
                            export industrial equipment and chemical machinery to Taiwan.
                        </div>
                    </div>
                    <div className="timeline-item">
                        <span>1987</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>Started raw material, manufacturing equipment, and technology business at the time of "Fine
                            Ceramic
                            boom". Launched Taiwan office.

                        </div>
                    </div>
                    <div className="timeline-item">
                        <span>1993</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>Moved the head office to East-Ikebukuro, Toshima-ku. Trading with China and South Korea
                            started.
                        </div>
                    </div>
                    <div className="timeline-item">
                        <span>1995</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>With the opening of the Beijing office, PTC developed the business centered around China.

                        </div>
                    </div>
                    <div className="timeline-item">
                        <span>1996</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>Planned and sold optical connector parts and exhibited at the Beijing exhibition PT / EXPO.

                        </div>
                    </div>
                    <div className="timeline-item">
                        <span>1997</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>Changed management policy from trading company activities to planning / development
                            engineering
                            business, and changed the corporate name to Pacific Technology Corporation.
                            Start importing business.
                        </div>
                    </div>
                    <div className="timeline-item">
                        <span>1998</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>Full-scale entry into the optical communication field. Sold optical measuring devices along
                            with the
                            optical connector parts. Established Shanghai office. Exhibited at the Beijing Exhibition.
                        </div>
                    </div>
                    <div className="timeline-item">
                        <span>1999</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>With the establishment of offices in Shenzhen and Wuhan, sales in optical connector parts
                            and optical
                            measuring devices expanded.
                            Exhibited at Shanghai China Elecomm.
                        </div>
                    </div>
                    <div className="timeline-item">
                        <span>2000</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>With the Tokyo Summit "Declaration of the IT Era", optical communication industry trended
                            upward.
                            Exhibited at Singapore Asia Comm.2000. Established Singapore Liaison Office.
                        </div>
                    </div>
                    <div className="timeline-item">
                        <span>2001</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>To meet the global shortage of ferrules, PTC provided a consistent production line from
                            ceramic ferrule
                            tube production to precision processing for China and Taiwan.
                        </div>
                    </div>
                    <div className="timeline-item">
                        <span>2002</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>Continued export of ferrule plants to Taiwan and China, exhibited at OFC in the US,
                            CommunicAsia2002 in
                            Singapore, and Interopt in Japan.
                            PTC to strengthen the sales of manufacturing equipment and raw materials for major ceramic
                            electronic
                            components such as MLCC, MLCI and CR. Established US office.
                        </div>
                    </div>
                    <div className="timeline-item">
                        <span>2003</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>Completed construction of the 6th ferrule plant for Taiwan and China. Completed delivery of
                            all plant
                            exports.
                            Head office moved to Nakano-ku, Tokyo.
                        </div>
                    </div>
                    <div className="timeline-item">
                        <span>2004</span>
                        <span className="timeline-dot"></span>
                        <span className="timeline-line"></span>
                        <div>Started procurement of Chinese chemical machinery and contract work for local construction
                            to meet the
                            demand of factory construction by many Japanese companies entering Chinese market.
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    )
}
